import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withProps } from 'recompose';
import styled from 'styled-components';
import { withDisableableTypeOnClick } from './hocs';
import {
	centionBlack,
	centionBlue,
	centionGrey,
	inputBorderRadius,
	inputBorderStyle
} from '../styles/_variables';

export const focusedBorderStyled = `
  border-color: ${centionBlue};
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${centionBlue}9e;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${centionBlue}9e;
`
class ButtonBlockBase extends React.PureComponent {
	constructor (props) {
		super(props);
	}
	render() {
		const {
				children
				, className
				, color
				, disabled
				, onClick
				, style
				, title: titleText
				, text
				, 'data-qa-id' : dataQAId
				, hide
				, ...props
			} = this.props
			;
		let title
			, hasColor
			, Tag = props.tag
			;
		if (disabled && typeof disabled === "string") {
			title = disabled;
		} else if (titleText) {
			title = titleText;
		} else {
			title = text;
		}
		if (color && typeof color === "string") {
			hasColor = true;
		}
		if (!Tag) {
			Tag = "button";
		}
		return (
			<Tag
				type="button"
				className={classNames(
					{"btn-blue": !hasColor, [`btn-${color}`]: hasColor}
					, className
				)}
				onClick={onClick}
				style={style}
				title={title}
				disabled={!!disabled}
				hidden={hide}
				data-qa-id={dataQAId ? dataQAId : "btn-"+title}
			>
				{children}
			</Tag>
		);
	}
}

export const ButtonBlock = withDisableableTypeOnClick(ButtonBlockBase);

ButtonBlock.propTypes = {
	children: PropTypes.node
	, className: PropTypes.string
	, color: PropTypes.string
	, disabled: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.bool
	])
	, onClick: PropTypes.func
	, style: PropTypes.object
	, text: PropTypes.string
	, title: PropTypes.string
	, hide: PropTypes.bool
};

const Button = props => <ButtonBlock {...props}>{props.text}</ButtonBlock>;

export default Button;

const PlainButtonBase = styled(Button)`
	&.btn-white {
		border: ${inputBorderStyle};
		border-radius: ${inputBorderRadius};
		padding: 7px 14px;
		font-size: 14px;
		&:focus {${focusedBorderStyled}}
	}
`
export const PlainButton = withProps({ color: 'white' })(PlainButtonBase)

export const AlphabetButton = ({ label, className, customStyle, onClick }) => {
	return (
		<div
			className={className}
			onClick={onClick}
			style={customStyle}
		>
			<div className="c3-aiIcon-Style">
				{label}
			</div>
		</div>
	)
}
export const CircleButton = ({ label, className, customStyle, onClick }) => {
	return (
		<ButtonWrapper
			className={className}
			onClick={onClick}
			style={customStyle}
		>
			<Circle>
				{label}
			</Circle>
		</ButtonWrapper>
	);
};

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 18px; 
	height: 18px;
`;

const Circle = styled.div`
	width: 18px; 
	height: 18px;
	background-color: white;
	color: #EC6A52;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px; 
	font-weight: bold;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center; 
	gap: 5px; 
	position: absolute;
	background-color: #EC6A52;
	border-radius: 5px; 
	padding: 6px 12px; 
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Divider = styled.div`
	width: 1px; 
	height: 20px; 
	background-color: rgba(255, 255, 255, 0.5);
`;

export const ButtonsGroup = ({ onClickPr, onClickSum, customStyle }) => (
	<ButtonContainer style={customStyle}>
		<CircleButton
			label="P"
			onClick={onClickPr}
		/>
		<Divider /> 
		<CircleButton
			label="S"
			onClick={onClickSum}
		/>
	</ButtonContainer>
);

ButtonsGroup.propTypes = {
	onClickPr: PropTypes.func.isRequired,
	onClickSum: PropTypes.func.isRequired,
	customStyle: PropTypes.object,
};