var webserverRoot = window.location.protocol + "//" + window.location.hostname + process.env.PATH_PREFIX + "Cention/web/";
import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState,
  useEffect
} from 'react'
import classNames from 'classnames'
import { withContentRect } from 'react-measure'
import update from 'immutability-helper'
import styled from 'styled-components'
import { branch, renderComponent, withProps } from 'recompose'
import { Manager, Reference, Popper } from 'react-popper'
import { I, L } from '../common/v5/config';
import { cssUnitsInt, replaceEmptyArray } from '../common/helpers'
import {
  COMMON_OFFSET_MODIFIER,
  PF_HISTORY_CREATED,
  PF_TIMESTAMP,
  UNKNOWN_DATE,
  ACQ_NO_HISTORY,
  ACQ_HAS_HISTORY,
  ACQ_HAS_OPEN_ERRAND,
} from '../common/v5/constants'
import {
  TXT_UNKNOWN
  , SIP_CALL_CONNECTING
  , SIP_OUTGOING_CALL_CONNECTING
  , SIP_CALL_CONNECTED
  , SIP_OUTGOING_CALL_CONNECTED
 } from '../common/v5/callConstants'
import { splitAndAdd } from './common'
import useAsyncState, { useAutoAsync } from '../hooks/async'
import {
  centionBlue,
  fontSizeGeneral,
  inputBorderRadius,
  inputDoubleSpace,
  inputPadding,
  spanPadding,
  zIndexLowerPopup,
  centionGreen,
  centionGrey
} from '../styles/_variables'
import { isSipConnected } from '../common/v5/helpers';
import { GlobalModal } from '../hooks/portal'
import { useShowAndHide } from '../hooks/toggle'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import withOverflowControl from '../styles/overflow'
import AddMore, { TXT_ADD_MORE } from './AddMore'
import {
  MoreDisplayIDs,
  withAssociatedErrandItems
} from './AssociatedErrandDisplayIDs'
import { ChannelIcon } from './Channel'
import ClientSatisfactionMeter from './ClientSatisfactionMeter'
import { withReplyToolbar } from "../containers/toolbar"
import { ChannelDropdown } from '../components/v5/ManualErrand'
import { Spinner } from '../reactcomponents/Spinner';
import SelectFilter from '../reactcomponents/SelectFilter'

export const HistoryDataInProgress = () => (
  <h3>{I('Fetching history data ...')}</h3>
)

const HistoryOrOtherQuestionAnswerBase = ({
  answerComponent: Answer,
  avatars,
  canTranslate,
  currentErrandData,
  currentErrandId,
  currentThreadId,
  data,
  area,
  forward,
  isWhatsapp,
  forwardSelection,
  interfaceLang,
  questionComponent: Question,
  onActionClick,
  onSelectForward,
  onSetErrandMessageTruncatePref,
  onClickAgentAssistTrigger,
  onSend,
  onSocialMediaUIAction,
  onTranslation,
  previousThreadId,
  showAgentMessages,
  showClientMessages,
  showHistorySummary,
  sumHistory,
  truncateErrandMessageByDefault
}) => {
  const {
    actions,
    answer,
    channel,
    channelId,
    created,
    displayId,
    eid,
    errandOwner,
    from,
    fromId,
    htmlanswer,
    htmlmessage,
    incoming,
    lastAction,
    lastSaved,
    message,
    outgoing,
    socialEmotions,
    tagsList,
    threadId,
    wasCloned
  } = data;
  const selected = forwardSelection[eid]
  const isCurrentOpen = currentErrandId == eid
  let { answerEmoticons, automatedAnswer } = data
  let msg
  let answerMsg
  if (htmlmessage) {
    msg = htmlmessage
  } else {
    msg = message
  }
  if (htmlanswer) {
    answerMsg = htmlanswer
  } else {
    answerMsg = answer
  }
  // TODO: fix this
  // eslint-disable-next-line no-undef
  if (channelId === Workflow.Errand.SERVICE_MANUAL_FACEBOOK) {
    msg = answerMsg
    answerMsg = ''
    answerEmoticons = null
  }
  let { client } = currentErrandData
  if (typeof avatars !== 'undefined') {
    const ob = avatars[fromId]
    if (ob && ob.avatar) {
      client = { photo: ob.avatar.url, name: ob.fromAddress }
    }
  }
  const isCurrentThread = currentThreadId === threadId
  // answer part
  let answered
  let answerTime
  let parseFormat
  let actionType
  if (lastAction) {
    answered = true
    answerTime = lastAction.dateTime
    parseFormat = PF_TIMESTAMP
    actionType = lastAction.actionType
  } else if (lastSaved) {
    answerTime = lastSaved.dateTime
    parseFormat = PF_TIMESTAMP
  } else if (answerMsg) {
    answerTime = created
    parseFormat = PF_HISTORY_CREATED
  } else if (isCurrentThread) {
    answerTime = UNKNOWN_DATE
  }
  if (!isCurrentThread) {
    forward = false
  }
  const hasAnswer = !!answerTime
  const _actions = replaceEmptyArray(actions)
  let fromAi= ""
  const noQuestion = !showClientMessages && !showHistorySummary ||
    (isCurrentThread && previousThreadId === currentThreadId && wasCloned)
  let createdDate = created
  if(showHistorySummary){
    if(sumHistory){
      fromAi= "Summary"
      msg = sumHistory.summarizedHistory
    }
  }
  if(features["whatsapp-history-7days"] && isWhatsapp){// for whatsapp only 
    msg  =`${answer}`;
    if(data.date){
      createdDate=data.date
    }
  }

  return (
    // eslint-disable-next-line react/jsx-fragments
  <Fragment>
      <Question
        actionsHistory={_actions || []}
        attachments={incoming || []} 
        canTranslate={canTranslate || false}
        channel={channel || ""}
        channelId={channelId ||""}
        delete={false} // history can't be deleted
        eid={currentErrandId || ""}
        area={area || ""}
        forward={forward || false}
        from={from || fromAi}
        hasAnswer={hasAnswer || false}
        hid={displayId || ""}
        hidden={noQuestion}
        html={msg || ""}
        isCurrentOpen={isCurrentOpen || false}
        interfaceLang={interfaceLang ||  ""}
        myErrandId={eid ||  ""}
        onActionClick={onActionClick || (() => {})}
        onSelectForward={onSelectForward || (() => {})}
        onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref || (() => {})}
        onClickAgentAssistTrigger={onClickAgentAssistTrigger || (() => {})}
        onSend={onSend}
        onSocialMediaUIAction={onSocialMediaUIAction ||(() => {})}
        onTranslation={onTranslation || (() => {})}
        parseFormat={PF_HISTORY_CREATED || ""}
        selected={selected || false}
        showErrandNumber
        socialEmoticon={socialEmotions || []}
        timestamp={createdDate}
        truncateErrandMessageByDefault={truncateErrandMessageByDefault}
        writer={client || ""}
      />
      <Answer
        actionType={actionType}
        actionsHistory={_actions}
        answered={answered}
        attachments={outgoing}
        channelId={channelId}
        delete={false} // history can't be deleted
        edit={false}
        eid={currentErrandId}
        area={area}
        forward={forward}
        hid={displayId}
        hidden={!showAgentMessages || !hasAnswer}
        html={answerMsg}
        isCurrentOpen={isCurrentOpen}
        myErrandId={eid}
        onActionClick={onActionClick}
        onSelectForward={onSelectForward}
        onSocialMediaUIAction={onSocialMediaUIAction}
        parseFormat={parseFormat}
        print={false}
        selected={selected}
        showErrandNumber={noQuestion}
        socialEmoticon={answerEmoticons}
        tags={tagsList}
        timestamp={answerTime}
        writer={errandOwner}
        automatedAnswer={automatedAnswer}
      />
    </Fragment>
  )
}

const withAutoFetchHistoryData = Component => ({
  errandId,
  onFetchHistory,
  threads,
  ...props
}) => {
  const { data } = props
  const threadId = threads[errandId]
  const [fetcher, fetching, error] = useAsyncState(onFetchHistory)
  const autoFetcher = useCallback(
    () => fetcher(errandId, threadId),
    [errandId, fetcher, threadId]
  )
  useAutoAsync(autoFetcher, data, fetching)
  return <Component error={error} onFetchHistory={fetcher} {...props} />
}

const HistoryOrOtherQuestionAnswer = composeWithDisplayName(
  'HistoryOrOtherQuestionAnswer',
  memo,
  withAutoFetchHistoryData,
  branch(({ data }) => !data, renderComponent(HistoryDataInProgress))
)(HistoryOrOtherQuestionAnswerBase)

const getChatSatisfactionMeter = errand => {
  if (errand &&
    // TODO: fix this
    // eslint-disable-next-line no-undef
    errand.service === Workflow.Errand.SERVICE_CHAT &&
    errand.chatSatisfactionMeter &&
    errand.chatSatisfactionMeter.rating !== '') {
    return errand.chatSatisfactionMeter
  }
}

const CustomerSatisfactionMeter = composeWithDisplayName(
  'CustomerSatisfactionMeter',
  withUnmountWhenHidden,
  memo
)(ClientSatisfactionMeter)

const StyledLoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  .btnLoadMore {
    padding: 10px;
    border-radius: 4px;
    &:hover {
      background-color: #EAEAEA;
    }
  }
  span {
    color: #0C87F7;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  i {
    font-size: 11px;
    color: #0C87F7;
    margin-right: 5px;
    &.icon-reload {
      font-size: 12px;
    }
  }
`

const HistoriesAndOthersBase = ({
  answerComponent,
  canTranslate,
  data: { historiesAndOthers: { byId, allIds, threads }, my, avatars },
  eid,
  filterPopup: { allMessages, bySumHistory, byAgents, byCustomers },
  forward,
  forwardSelection,
  hideSatisfaction,
  interfaceLang,
  onActionClick,
  onFetchHistory,
  onSelectForward,
  onSetErrandMessageTruncatePref,
  onClickAgentAssistTrigger,
  onSend,
  onSocialMediaUIAction,
  onTranslation,
  questionComponent,
  truncateErrandMessageByDefault,
  historyIds,
  jumpID,
  sortHistoryAscending,
  channelId,
  areaId
  ,...props
}) => {
  let previousThreadId
  let sumLoading = props?.sumHistory?.loading
  const csmData = getChatSatisfactionMeter(my.errand)
  const loadMoreHistoryCflag = cflag.IsActive("2023-09-08.CEN-1519.limit.errand.history");
  const historyOffset = 50; // Number of history to load per click
  let historyLimit = historyOffset;
  if(!loadMoreHistoryCflag) {
    historyLimit = allIds.length;
  }
  const [visibleItems, setVisibleItems] = useState(historyLimit);
  const [loadingMore, setLoadingMore] = useState(false);
  const [historyLoading, setHistoryLoading]= useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setVisibleItems(prevVisibleItems => prevVisibleItems + historyOffset);
      setLoadingMore(false);
    }
      , 1000);
  }
  const loadPreviousHistory = () => {
    var url = '/Cention/web/errand/listerrand/' + eid + '?nocache=' + Math.random();
		var parameters = {
			answered: true,
			unanswered: true,
			acquirable: false,
      offset: currentIndex,
			limit: 25
		};
		$.getJSON(url, parameters).done(function(data) {
      setHistoryLoading(prevHistoryLoading => Array.isArray(prevHistoryLoading) ? [...prevHistoryLoading, ...data.list] : data.list);
		}.bind(this));
    setLoadingMore(true);
    setTimeout(() => {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setLoadingMore(false);
    }
    , 1000);
  }
  let showLoadMore = false;
  if (visibleItems < allIds.length) {
    showLoadMore = true;
  }
  const loadMoreHistoryTxt = I("Load more history");

  useEffect(() => {
    const sortedHistoryIds = [];
    if(historyIds && historyIds.length > 0) {
       sortedHistoryIds.push(...historyIds);
    }
    if (sortHistoryAscending) {
      sortedHistoryIds.sort((a, b) => {
        let numA = parseInt(a.split('-')[1]);
        let numB = parseInt(b.split('-')[1]);

        return numA - numB;
      });
    }

    if (jumpID && sortedHistoryIds && sortedHistoryIds.length > 0) {
      let jumpIndex = sortedHistoryIds.indexOf(jumpID);
      if (jumpIndex > -1) {
        let offset = Math.floor(jumpIndex / historyOffset);
        setVisibleItems((offset + 1) * historyOffset);
      }
    }
  }, [jumpID]);

  useEffect(() => {
    if (visibleItems && jumpID) {
      const targetErrand = document.getElementById(`errand-history-${jumpID}`);
      if (targetErrand) {
        targetErrand.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  , [visibleItems, jumpID]);

  const checkWhatsAppSevenDayHistoryActive = async () => {
    // Posting data to your server and awaiting the response
    const response = await $.post(webserverRoot + 'errand/areaData', { areas: areaId });
  
    // Check and process the response for each channel data
    response.mcam.channels.forEach(function(channel) {
      if (channel.type === "Result") {
        // Parsing the JSON content of the channel
        const areaData = JSON.parse(channel.content);
  
        // Check if the 7-day WhatsApp history feature is active
        if (areaData.hasSevenDaysWhatsapp) {
          updateVisibleHistory();
        }

      }
    });
  
  };
  
  const updateVisibleHistory = async () => {
    if (!historyLoading || historyLoading.length === 0 ) {
      loadPreviousHistory();
    }
  };
  

  useEffect(() => {
    // Check if the channel ID is for WhatsApp and the 7-day history feature is enabled
    if (channelId === 23 && features["whatsapp-history-7days"]) {
      checkWhatsAppSevenDayHistoryActive();
    }
  }, [channelId, historyLoading.length, features]);
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <CustomerSatisfactionMeter
        clientChatSatisfaction
        hidden={hideSatisfaction || !csmData}
        csmData={csmData}
      />
      {sumLoading && 
      <div className="loading-bar-container">
						<div className="loading-bar-filler"></div>
					</div>
          }
      {!sumLoading && !bySumHistory && allIds.slice(0, visibleItems).map(id => {
        const data = byId[id];
        const _previousThreadId = previousThreadId;
        previousThreadId = data ? data.threadId : 0;
        return (
          <HistoryOrOtherQuestionAnswer
            key={id}
            answerComponent={answerComponent}
            avatars={avatars}
            canTranslate={canTranslate}
            currentErrandData={my}
            currentErrandId={eid}
            area={props?.area?.id}
            currentThreadId={my.history.threadId}
            data={data}
            errandId={id}
            forward={forward}
            forwardSelection={forwardSelection}
            interfaceLang={interfaceLang}
            onActionClick={onActionClick}
            onFetchHistory={onFetchHistory}
            onSelectForward={onSelectForward}
            onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
            onClickAgentAssistTrigger={onClickAgentAssistTrigger}
            onSend={onSend}
            onSocialMediaUIAction={onSocialMediaUIAction}
            onTranslation={onTranslation}
            previousThreadId={_previousThreadId}
            questionComponent={questionComponent}
            showAgentMessages={allMessages || byAgents}
            showClientMessages={allMessages || byCustomers}
            showHistorySummary={bySumHistory}
            threads={threads}
            sumHistory={props.sumHistory}
            truncateErrandMessageByDefault={truncateErrandMessageByDefault}
          />
        )
      })}
           {bySumHistory &&
          <HistoryOrOtherQuestionAnswer
          key={1}
          answerComponent={answerComponent}
          isWhatsapp={true}
          avatars={[]}
          canTranslate={false}
          currentErrandData={{}}
          currentErrandId=""
          area={props?.area?.id}
          currentThreadId=""
          data={{}}
          errandId=""
          forward={false}
          forwardSelection={false}
          interfaceLang=""
          onActionClick={() => {}}
          onFetchHistory={() => {}}
          onSelectForward={() => {}}
          onSetErrandMessageTruncatePref={() => {}}
          onClickAgentAssistTrigger={onClickAgentAssistTrigger}
          onSend={onSend}
          onSocialMediaUIAction={() => {}}
          onTranslation={() => {}}
          previousThreadId=""
          questionComponent={questionComponent}
          showAgentMessages={false}
          showClientMessages={false}
          showHistorySummary={bySumHistory}
          threads={[]}
          sumHistory={props.sumHistory}
          truncateErrandMessageByDefault={false}
        />
          }
     {historyLoading?.map((item, id) => (
          <HistoryOrOtherQuestionAnswer
            key={id}
            answerComponent={answerComponent}
            isWhatsapp={true}
            avatars={avatars}
            canTranslate={canTranslate}
            currentErrandData={my}
            currentErrandId={eid}
            area={props?.area?.id}
            currentThreadId={my.history.threadId}
            data={item.data}
            errandId={id}
            forward={forward}
            forwardSelection={forwardSelection}
            interfaceLang={interfaceLang}
            onActionClick={onActionClick}
            onFetchHistory={onFetchHistory}
            onSelectForward={onSelectForward}
            onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
            onClickAgentAssistTrigger={onClickAgentAssistTrigger}
            onSend={onSend}
            onSocialMediaUIAction={onSocialMediaUIAction}
            onTranslation={onTranslation}
            previousThreadId={id}
            questionComponent={questionComponent}
            showAgentMessages={allMessages || byAgents}
            showClientMessages={allMessages || byCustomers}
            showHistorySummary={bySumHistory}
            threads={threads}
            truncateErrandMessageByDefault={truncateErrandMessageByDefault}
          />
          ))}
        {loadMoreHistoryCflag && showLoadMore &&
          <StyledLoadMore onClick={handleLoadMore} title={loadMoreHistoryTxt}>
            <div className='btnLoadMore'>
              <i className='icon-reload' hidden={loadingMore} />
              {
                loadingMore && <Spinner />
              }
              <span>{loadMoreHistoryTxt}</span>
            </div>
        </StyledLoadMore>
      }
    </Fragment>
  )
}

export const HistoriesAndOthers = branch(
  ({ ready }) => !ready,
  renderComponent(HistoryDataInProgress)
)(HistoriesAndOthersBase)

const withHistoriesOrOthers = (
  Component,
  QuestionComponent,
  AnswerComponent
) => ({
  historyReady,
  onFetchHistory,
  onPopAlert,
  showInternalComment,
  showCollaboration,
  ...props
}) => (
  <Component
    showCollaboration={showCollaboration}
    showInternalComment={showInternalComment}
    {...props}
  >
    <HistoriesAndOthers
      answerComponent={AnswerComponent}
      onFetchHistory={onFetchHistory}
      onPopAlert={onPopAlert}
      questionComponent={QuestionComponent}
      ready={historyReady}
      {...props}
    />
  </Component>
)

export default withHistoriesOrOthers

const TXT_AGENT_REPLIED_ERRANDS = I('{AGENT_NAME} answered {CURRENT_ERRAND} with {ASSOCIATED_ERRANDS} via {CHANNEL}')

const TXT_AGENT_CLOSED_ERRANDS = I('{AGENT_NAME} closed {CURRENT_ERRAND} with {ASSOCIATED_ERRANDS} via {CHANNEL}')

const TXT_AGENT_LINK_ERRANDS = I('{AGENT_NAME} manually linked {LINKED_ERRANDS}')

const TXT_AGENT_REPLIED_ONE_ERRAND = I('{AGENT_NAME} answered {CURRENT_ERRAND} via {CHANNEL}')

const TXT_AGENT_CLOSED_ONE_ERRAND = I('{AGENT_NAME} closed {CURRENT_ERRAND} via {CHANNEL}')

const Text = ({ className, text, title }) => (
  <span className={className} title={title}>{text}</span>
)

const AgentName = ({ name }) => <Text text={name} />

const ColorText = styled(Text)`
  color: ${centionBlue};
`
const AssociatedErrands = ({ associatedErrands }) => (
  <ColorText text={associatedErrands.join(' ')} />
)

const LinkedErrands = ({ linkedErrands }) => (
  <ColorText text={linkedErrands.join(' ')} />
)

const styleI18n = (Component, ...args) => splitAndAdd(Component, Text, ...args)

const getChannelName = (channelId, servicesByType) => {
  const channel = servicesByType[channelId]
  if (!channel || !channel.name) {
    return TXT_UNKNOWN
  }
  return channel.name
}

const Channel = ({ channelId, servicesByType }) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    <ChannelIcon channelId={channelId} />
    <ColorText text={getChannelName(channelId, servicesByType)} />
  </Fragment>
)

const useDisplayId = displayId => useMemo(() => `#${displayId}`, [displayId])

const CurrentErrand = ({ displayId }) => (
  <ColorText text={useDisplayId(displayId)} />
)

const DynamicVariable = ({
  associatedErrands,
  linkedErrands,
  channelId,
  displayId,
  servicesByType,
  variable,
  writer: { name }
}) => {
  switch (variable) {
    case '{AGENT_NAME}':
      return <AgentName name={name} />
    case '{ASSOCIATED_ERRANDS}':
      return <AssociatedErrands associatedErrands={associatedErrands} />
    case '{CHANNEL}':
      return <Channel channelId={channelId} servicesByType={servicesByType} />
    case '{CURRENT_ERRAND}':
      return <CurrentErrand displayId={displayId} />
     case '{LINKED_ERRANDS}':
        return <LinkedErrands linkedErrands={linkedErrands} />
    default:
      return null
  }
}

const singleErrandVariableList = [
  '{AGENT_NAME}',
  '{CHANNEL}',
  '{CURRENT_ERRAND}',
  '{LINKED_ERRANDS}'
]

const variableListForErrands = update(
  singleErrandVariableList,
  { $push: ['{ASSOCIATED_ERRANDS}'] }
)

const morevariableListForErrands = update(
  variableListForErrands,
  { $push: ['{LINKED_ERRANDS}'] }
)

const RepliedNoteBase = ({ text, variableList, ...props }) => {
  const all = []
  styleI18n(DynamicVariable, props, variableList, all, 0, text)
  return <span>{all}</span>
}

export const RepliedNote = composeWithDisplayName(
  'RepliedNote',
  memo,
  withProps(({ associatedErrands, isClosedNoAnswer }) => {
    if (associatedErrands && associatedErrands.length) {
		if(isClosedNoAnswer) {
			return {
				text: TXT_AGENT_CLOSED_ERRANDS,
				variableList: variableListForErrands
			}
		} else {
			return {
				text: TXT_AGENT_REPLIED_ERRANDS,
				variableList: variableListForErrands
			}
		}
    }
	if(isClosedNoAnswer) {
		return {
			text: TXT_AGENT_CLOSED_ONE_ERRAND,
			variableList: singleErrandVariableList
		}
	}
    return {
      text: TXT_AGENT_REPLIED_ONE_ERRAND,
      variableList: singleErrandVariableList
    }
  })
)(RepliedNoteBase)

export const LinkedErrandsNote = composeWithDisplayName(
  'LinkedErrandsNote',
  memo,
  withProps(() => {
      return {
        text: TXT_AGENT_LINK_ERRANDS,
        variableList: morevariableListForErrands
      }
  })
)(RepliedNoteBase)

const ErrandDisplayIDBase = ({ className, displayId, ...props }) => (
  <Text
    className={classNames(className, 'display-id')}
    text={useDisplayId(displayId)}
    {...props}
  />
)

export const ErrandDisplayID = styled(ErrandDisplayIDBase)`
  &.display-id {
    background-color: ${centionBlue};
    border-radius: 8px;
    color: #fff;
    padding: ${spanPadding};
  }
`
const ReplyingToBase = ({ channelId, className, displayId }) => {
  const TXT_REPLYING_TO = I('Replying to')
return(
  <div className={className}>
    <div className='title'>{TXT_REPLYING_TO}</div>
    <div className='current'>
      <ChannelIcon channelId={channelId} />
      <ErrandDisplayID displayId={displayId} />
    </div>
  </div>
)};

const StyledReplyingTo = styled(ReplyingToBase)`
  div& {
    display: flex;
    flex-direction: column;
    div.title {
      margin-bottom: 4px;
    }
    div.current {
      white-space: nowrap;
      .display-id {
        font-weight: 800;
        white-space: nowrap;
      }
    }
  }
`
const ReplyingTo = composeWithDisplayName(
  'ReplyingTo',
  memo
)(StyledReplyingTo)

const StyledSpan = styled.span`
  display: flex;
  font-size: ${fontSizeGeneral};
  background-color: #ffffffb8;
  border-radius: ${inputBorderRadius};
  padding: ${inputPadding};
  align-items: flex-end;
  max-width: 100%;
  > * + * {
    margin-left: ${inputDoubleSpace};
  }
  .add-more {
    span {
      a.anchor {
        white-space: nowrap;
      }
    }
  }
`
const StyledDiv = styled.div`
  z-index: ${zIndexLowerPopup};
`
const preComponentsWidth = 245
const StyledOverflowSpan = styled.span`
  display: flex;
  flex-grow: 1;
  max-width: calc(100% - ${preComponentsWidth}px);
  overflow: visible;
  > span + span, > div.show-more, .show-more .tooltiptext > span + span {
    margin-left: ${inputDoubleSpace};
  }
`

const StyledMakeCall = styled.span`
  background: ${props => props.disabled ? centionGrey : centionGreen};
  border-radius: 8px;
  padding: 2px 6px;
  color: #fff;
  font-weight: 800;
  margin-left: 0px;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? "none" : "unset"};
`

const OthersBase = ({ fitCount, items, measureRef }) => (
  <StyledOverflowSpan className='others' ref={measureRef} title='test'>
    {items.slice(0, fitCount)}
    <MoreDisplayIDs tags={items.slice(fitCount)} />
  </StyledOverflowSpan>
)

const parentMarginLeft = cssUnitsInt(inputDoubleSpace)
const parentMarginRight = cssUnitsInt(inputDoubleSpace)
const selfMarginLeft = cssUnitsInt(inputDoubleSpace)
const unusablePaddingMarginWidth = parentMarginLeft + parentMarginRight + selfMarginLeft
const unusableWidth = preComponentsWidth + unusablePaddingMarginWidth

const Others = composeWithDisplayName(
  'Others',
  withAssociatedErrandItems,
  withProps(({ items, parentWidth }) => {
    let itemSize
    if (!items || !items.length) {
      itemSize = 0
    } else {
      itemSize = items.length
    }
    return {
      availableWidth: parentWidth > unusableWidth ? parentWidth - unusableWidth : 0,
      itemSize
    }
  }),
  withOverflowControl
)(OthersBase)

const StyledWrapper = styled.div`
  width: ${props => props.verticalView ? '85%' : '100%'};
`

const AddMoreBtn = ({className, innerRef, onClick, dataQid, title, icon,text}) => {
  return (
    <div
        className={className}
        ref={innerRef}
        onClick={onClick}
        title={title}
        data-qa-id={dataQid}
      >
        <i className={icon} />
        <span className='text'>{text}</span>
      </div>
  )
}

const withCurrentAndAddMoreOthersBase = Component => ({
  associatedErrands,
  channelId,
  contentRect: { client: { width } },
  displayId,
  measureRef,
  onClickInfo,
  onOpenAcquiredErrand,
  onMakeCall,
  sipCallStatus,
  sipCallBackInfo,
  isClosed,
  isCallMinimized,
  showAcquire,
  hasOtherErrands,
  hasOpenErrand,
  showReplyboxErrandsMenu,
  onToggleReplyBoxErrandMenu,
  historyIds,
  onJumpHistory,
  verticalView,
  ...p
}) => {
  const [show, , onHide, onToggle] = useShowAndHide();
  let hideMakeCall = true, disableMakeCall = false, sipConnected = false;
  if(sipCallStatus === SIP_OUTGOING_CALL_CONNECTED ||
    sipCallStatus === SIP_CALL_CONNECTED ||
    sipCallStatus === SIP_CALL_CONNECTING ||
    sipCallStatus === SIP_OUTGOING_CALL_CONNECTING ||
    isCallMinimized) {
      disableMakeCall = true;
    }
  if(isSipConnected(sipCallStatus)) {
    sipConnected = true;
  }
  if(((channelId === Workflow.Errand.SERVICE_VOICE ||
    channelId === Workflow.Errand.SERVICE_MANUAL_VOICE ||
	channelId === Workflow.Errand.SERVICE_SIP_VOICE)
    || (channelId === Workflow.Errand.SERVICE_CHAT && sipCallBackInfo && sipCallBackInfo.enable))
    && features["sip-enabled"] && sipConnected) {
      if(!isClosed) {
        hideMakeCall = false;
      }
    }
  const acqBttnClass = classNames(
    'ignore-react-onclickoutside'
    ,'acquired-button'
    , {'with-errands': hasOtherErrands, 'has-acquirable': hasOpenErrand}
  )
  let acquireLabel = ACQ_NO_HISTORY;
  if(hasOtherErrands){
    acquireLabel = ACQ_HAS_HISTORY;
  }
  if (hasOpenErrand) {
    acquireLabel = ACQ_HAS_OPEN_ERRAND
  }

  const CflagEnableToJumpHistory = cflag.IsActive("2024-06-04.CEN-2309.jump-history-shortcut-option");
  const ShowJumpToOption = (channelId === Workflow.Errand.SERVICE_CHAT ? false : (CflagEnableToJumpHistory ? true : false));

  const handleJumpToHistory = (id) => {
    if(onJumpHistory) {
      onJumpHistory(id);
    }
  }

  return (
    <StyledWrapper ref={measureRef} verticalView={verticalView}>
      <StyledSpan className='add-more-others'>
        <ReplyingTo channelId={channelId} displayId={displayId} />
          <ChannelDropdown
            showChannelOpt={p.showChannelOpt}
            ddTitle={I('Channels')}
            collabOpts={p.collabOpts}
            options={p.channelOpts}
            selectedChannelAsIconTitle={true}
            {...p}
          />
        <span className='add-more'>
          <Manager>
            <Reference>
              {({ ref }) => (
                <AddMoreBtn
                  className={acqBttnClass}
                  innerRef={ref}
                  onClick={() => onToggleReplyBoxErrandMenu(true)}
                  title={acquireLabel}
                  text={L(TXT_ADD_MORE)}
                  icon={'icon-add'}
                  dataQid={"QA_openAcquiredErrands"}
                  showReplyboxErrandsMenu={showReplyboxErrandsMenu}
                  toggleReplyBoxErrandMenu={onToggleReplyBoxErrandMenu}
                />
              )}
            </Reference>
            <GlobalModal>
              <Popper
                modifiers={COMMON_OFFSET_MODIFIER}
                placement='bottom-start'
              >
                {({ placement, ref, style }) => {
                  if (!showReplyboxErrandsMenu) {
                    return null
                  }
                  return (
                    <StyledDiv
                      ref={ref}
                      style={style}
                      data-placement={placement}
                    >
                      <Component showAcquire={showReplyboxErrandsMenu}
                        onClickOutside={() => onToggleReplyBoxErrandMenu(false)}
                      />
                    </StyledDiv>
                  )
                }}
              </Popper>
            </GlobalModal>
          </Manager>
        </span>
        {
          !hideMakeCall &&
            <div>
              <ChannelIcon channelId={channelId} />
              <StyledMakeCall onClick={onMakeCall} disabled={disableMakeCall}>
                {I("Make call")}
              </StyledMakeCall>
            </div>
        }
        <Others
          associatedErrands={associatedErrands}
          newDesign
          onClick={onClickInfo}
          onOpen={onOpenAcquiredErrand}
          parentWidth={width}
        />
      </StyledSpan>
      {
        ShowJumpToOption &&
        <span className = "history-box">
          <SelectFilter withToggle={true} toggleTitle={I("Jump to history")} list={historyIds} onClickList={handleJumpToHistory} />
        </span>
      }
    </StyledWrapper>
  )
}

export const withCurrentAndAddMoreOthers = composeWithDisplayName(
  'withCurrentAndAddMoreOthers',
  memo,
  withReplyToolbar,
  withProps({ showChannelOpt: true }), //todo: use dynamic value logic
  withContentRect('client'),
  withCurrentAndAddMoreOthersBase
)
